import axios from "axios";

const requestToCastingVote = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "https://test.castingvote.im/api/",
  withCredentials: true,
});

requestToCastingVote.interceptors.request.use(
  (response) => {
    return response;
  },
  function (error) {}
);

requestToCastingVote.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error.response?.data.code === "ERR4001" && !originalRequest._retry) {
      originalRequest._retry = true;
      window.location.replace("/login?code=4001");
      localStorage.clear();
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default requestToCastingVote;
